import {getContract} from "../web3";
import ERC721 from "../web3/abi/ERC721.json";
import {getGalleryNFTAddress, getNFTTokenAddress} from "../web3/address";

var API_BASE = 'https://api1.piction.network/api/'

export async function fetchApi(url, params, library, chainId) {
  console.log('library', API_BASE + url)
  var resp = await fetch(API_BASE + url, params)
  if (resp.status != 200) {
    throw new Error(resp.statusText)
  }
  let cards = await resp.json()
  console.log('cards', cards, library)
  const contract = getContract(library, ERC721.abi, getNFTTokenAddress(chainId))

  if(!cards.tokens){
    return  cards
  }
  cards.tokens = await Promise.all(cards.tokens.map(async item => {
    const card = item
    card.owner = await contract.methods.ownerOf(item.tokenId).call()
    console.log('token owner', item.tokenId, item.name, card.owner)

    return card
  }))
  return cards
}
