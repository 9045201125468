import React from "react";

export const LogoSmallIcon = () => (
    <svg
        width="61"
        height="38"
        viewBox="0 0 61 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.0401 3.28488V1H52.9891C49.8326 1 46.9025 2.57828 45.1513 5.22246L36.7833 17.8576H20.671V20.1424H35.2701L27.7416 31.51C26.4126 33.517 24.1884 34.7151 21.7923 34.7151H18.8816C10.2732 34.7151 3.26984 27.6651 3.26984 18.9997C3.26984 10.3346 10.2732 3.28488 18.8816 3.28488H37.284V1H18.8816C9.02163 1 1 9.07477 1 19C1 28.9252 9.02163 37 18.8816 37H21.7923C24.9491 37 27.8789 35.4217 29.6301 32.7775L37.9982 20.1424H53.2025V17.8576H39.5113L47.0398 6.49C48.3691 4.48301 50.5933 3.28488 52.9891 3.28488H60.0401Z"
            fill="white"
            stroke="white"
        />
    </svg>
);
